import React from "react";
import { motion } from "framer-motion";
import image1 from "../Assets/map.jpeg";
import image2 from "../Assets/image 2.png";
import image3 from "../Assets/constructionteam.jpeg";
import { FaFacebook, FaInstagram, FaYelp } from "react-icons/fa6";

const AboutUs = () => {
  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: (delay) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 0.6,
        ease: "easeOut",
      },
    }),
  };
  const scaleVariants = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeInOut",
      },
    },
  };
  const scrollVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        ease: "easeOut",
      },
    },
  };
  return (
    <div>
      <div className="md:p-8 p-5">
        <motion.div
          variants={textVariants}
          initial="hidden"
          animate="visible"
          custom={0.1}
          className="p-5 text-center"
        >
          <h1 className="text-white font-serif xl:text-7xl lg:text-6xl md:text-5xl text-3xl font-bold">
            Building the Future, <br /> Restoring the Past
          </h1>
        </motion.div>
        <motion.div 
         initial="hidden"
         whileInView="visible"
         viewport={{ once: false, amount: 0.2 }}
         variants={scaleVariants}
         className="flex justify-center mt-2">
          <img
            src={image1}
            alt="Construction Site"
            className="w-full 2xl:max-w-[2000px] max-w-[1100px] object-contain rounded-lg"
          />
        </motion.div>
      </div>
      <motion.div 
       initial="hidden" 
       whileInView="visible" 
       viewport={{ once: false, amount: 0.2 }}
       variants={scrollVariants}
       className="relative">
        <img
          src={image2}
          alt="Construction Workers"
          className="w-full object-cover opacity-90"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
          <motion.h1 
           initial={{ opacity: 0, y: 40 }}
           whileInView={{ opacity: 1, y: 0 }}
           viewport={{ once: false }}
           transition={{ duration: 0.6 }}
           className="text-xl sm:text-3xl md:text-4xl 2xl:text-6xl font-serif font-bold mb-4">
            Global Presence
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-sm sm:text-xl md:text-2xl 2xl:text-3xl">
            Mon-Fri: 9am - 6pm
            <br />
            1234 Main St, Springfield, USA
          </motion.p>
        </div>
      </motion.div>
      <div className="md:p-8 p-5 mt-[100px]">
        <div className="flex justify-between flex-col lg:flex-row items-start lg:gap-10 gap-2">
          <motion.div 
           initial={{ opacity: 0, x: -100 }}
           whileInView={{ opacity: 1, x: 0 }}
           transition={{ duration: 0.8, ease: "easeOut" }}
           viewport={{ once: false, amount: 0.3 }}
           className="flex flex-col justify-start gap-3 lg:w-[50%] w-full">
            <h1 className="text-white font-serif font-bold text-xl sm:text-3xl xl:text-4xl 2xl:text-5xl">
              Our History
            </h1>
            <p className="text-[#d7dee4] text-sm sm:text-lg lg:text-xl 2xl:text-3xl">
              BuildCorp was founded in 1990 with a vision to revolutionize the
              construction industry. Over the years, we have expanded our
              operations globally, establishing a strong presence in over 20
              countries. Our commitment to quality and innovation has made us a
              leader in the construction sector.
            </p>
            <p className="text-gray-400 font-serif text-xl lg:text-2xl 2xl:text-3xl mt-4">
              Contact Us
            </p>
          </motion.div>
          <motion.div 
           initial={{ opacity: 0, x: -100 }}
           whileInView={{ opacity: 1, x: 0 }}
           transition={{ duration: 0.8, ease: "easeOut" }}
           viewport={{ once: false, amount: 0.3 }}
           className="flex justify-end lg:w-[50%] w-full">
            <img
              src={image3}
              alt="Workers Group"
              className="object-cover rounded-lg"
            />
          </motion.div>
        </div>
      </div>
      <motion.div 
        initial={{ opacity: 0, y: 90 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false }}
        transition={{ duration: 0.6 }}
        className="md:p-8 p-5 mt-[100px] text-center">
        <h1 className="text-white font-serif font-bold text-xl sm:text-2xl xl:text-4xl 2xl:text-5xl mb-5">
          Our Mission
        </h1>
        <p className="text-[#d7dee4] text-sm sm:text-lg lg:text-xl 2xl:text-3xl lg:px-[20%] sm:px-[5%]">
          To deliver exceptional construction services that not only meet but
          exceed our clients' expectations, while fostering a culture of safety,
          sustainability, and innovation.
        </p>
        <p className="text-gray-400 font-serif text-lg lg:text-2xl 2xl:text-3xl mt-4">
          Our Values
        </p>
      </motion.div>
      <motion.div 
        initial="hidden" 
        whileInView="visible" 
        viewport={{ once: false, amount: 0.2 }}
        variants={scrollVariants}
        className="md:p-8 p-5 mt-10">
       <div className="flex justify-center gap-7">
          {[FaFacebook, FaInstagram, FaYelp].map((Icon, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
            >
              <Icon className="text-white bg-black" size={38} />
            </motion.div>
          ))}
        </div>
        <motion.div 
          initial={{ opacity: 0, y: 90 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.6 }}
          className="text-center mt-10">
          <h1 className="text-white font-serif font-medium text-lg xl:text-xl 2xl:text-2xl">
            Building a Better Tomorrow
          </h1>
        </motion.div>
        <motion.div 
         initial={{ opacity: 0, y: 90 }}
         whileInView={{ opacity: 1, y: 0 }}
         viewport={{ once: false }}
         transition={{ duration: 0.6 }}
         className="flex justify-center gap-3 mt-3">
          <input
            type="text"
            className="p-1 px-2 rounded bg-[#182026]"
            placeholder="Email address"
          />
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded">
            Subscribe
          </motion.button>
        </motion.div>
        <motion.div 
         initial={{ opacity: 0, y: 90 }}
         whileInView={{ opacity: 1, y: 0 }}
         viewport={{ once: false }}
         transition={{ duration: 0.6 }}
         className="text-center mt-5">
          <p className="text-sm md:text-lg text-[#d7dee4]">
            1234 Main St, Springfield, USA
            <br />
            info@buildpro.com
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default AboutUs;
