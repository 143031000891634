import React from "react";
import { motion } from "framer-motion";
import image1 from "../Assets/engineers ai4.jpeg";
import image2 from "../Assets/site.jpg";
import image3 from "../Assets/imageDiv.png";
import avatar6 from "../Assets/avatar6.png";
import avatar7 from "../Assets/avatar7.png";
import avatar8 from "../Assets/avatar8.png";
import avatar9 from "../Assets/avatar9.png";
import avatar10 from "../Assets/avatar10.png";
import { FaFacebook, FaInstagram, FaYelp } from "react-icons/fa6";
const ContactUs = () => {

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (delay) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 0.6,
        ease: "easeOut",
      },
    }),
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        ease: "easeOut",
      },
    },
  };

  const scaleVariants = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };
  return (
    <div>
      <motion.div 
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.2 }}
        variants={textVariants}
        custom={0.2}
        className="md:p-8 p-5">
        <motion.div
          variants={textVariants}
          className="p-5 text-center"
        >
          <h1 className="text-white font-serif xl:text-7xl lg:text-6xl md:text-5xl text-3xl font-bold">
            Building the Future, One
            <br /> Project at a Time
          </h1>
        </motion.div>
        <motion.div 
         variants={scaleVariants}
         className="flex justify-center mt-2">
          <img
            src={image1}
            alt="Construction Site"
            className="w-full 2xl:max-w-[2000px] max-w-[1100px] object-contain rounded-lg"
          />
        </motion.div>
      </motion.div>
      <motion.div 
       initial="hidden"
       whileInView="visible"
       viewport={{ once: false, amount: 0.2 }}
       variants={sectionVariants}
       className="relative">
        <img
          src={image2}
          alt="Construction Site"
          className="w-full object-cover opacity-50"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
          <motion.h1 
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.6 }}
            className="text-xl sm:text-3xl md:text-4xl 2xl:text-6xl font-serif font-bold mb-4">
            Global Construction Services
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-sm sm:text-xl md:text-2xl 2xl:text-3xl">
            Mon-Fri: 8am - 5pm
            <br />
            1234 Main St, Springfield, USA
          </motion.p>
        </div>
      </motion.div>
      <motion.div 
       initial="hidden"
       whileInView="visible"
       viewport={{ once: false, amount: 0.2 }}
       variants={sectionVariants}
       className="md:p-8 p-5 flex lg:flex-row flex-col gap-5 mt-[100px]">
        <div className="lg:w-[50%] w-full flex flex-col items-start justify-center">
          <h1 className=" text-white font-serif font-bold text-xl sm:text-2xl xl:text-4xl 2xl:text-5xl">
            Our Services
          </h1>
          <p className="text-[#d7dee4] text-sm sm:text-lg lg:text-xl 2xl:text-3xl mt-2">
            We offer a wide range of construction services, from residential to
            commercial projects.
          </p>
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded mt-6"
          >
            Learn More
          </motion.button>
        </div>
        <motion.div 
          variants={scaleVariants}
          className="lg:w-[50%] w-full flex justify-center lg:justify-end"
        >
          <img
            src={image3}
            alt="Construction Site"
            className="max-h-[300px] sm:max-h-[500px] 2xl:max-h-[900px]"
          />
        </motion.div>
      </motion.div>
      <motion.div 
       initial="hidden"
       whileInView="visible"
       viewport={{ once: false, amount: 0.2 }}
       variants={sectionVariants}
       className="md:p-8 p-5 mt-[100px] mb-[100px]">
        <h1 className="mb-10 text-white font-serif font-bold text-xl sm:text-2xl xl:text-4xl 2xl:text-5xl text-center">
          What Our Clients Say
        </h1>
        <div className="overflow-x-auto scrollbar-hidden pb-4">
        <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.2 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: {
                opacity: 1,
                x: 0,
                transition: {
                  staggerChildren: 0.1,
                  delayChildren: 0.2,
                }
              }
            }}
            className="flex space-x-4 px-4"
          >
            {[
              { avatar: avatar6, name: "Alice Johnson", role: "CEO, TechCorp", quote: "BuildPro exceeded our expectations!" },
              { avatar: avatar7, name: "Michael Smith", role: "Manager, RealEstate", quote: "Professional and reliable." },
              { avatar: avatar8, name: "Emma Brown", role: "Owner, Brown's Bakery", quote: "Highly recommended BuildPro!" },
              { avatar: avatar9, name: "James White", role: "White Industries", quote: "Great Service and quality work." },
              { avatar: avatar10, name: "Emily Chen", role: "Architect", quote: "Seamless collaboration and exceptional craftsmanship." },
              { avatar: avatar10, name: "Emily Chen", role: "Architect", quote: "Seamless collaboration and exceptional craftsmanship." },
              { avatar: avatar10, name: "Emily Chen", role: "Architect", quote: "Seamless collaboration and exceptional craftsmanship." },
              { avatar: avatar10, name: "Emily Chen", role: "Architect", quote: "Seamless collaboration and exceptional craftsmanship." },
            ].map((testimonial, index) => (
              <motion.div 
                key={index}
                variants={{
                  hidden: { opacity: 0, x: -50 },
                  visible: { 
                    opacity: 1, 
                    x: 0,
                    transition: {
                      duration: 0.5,
                      ease: "easeOut"
                    }
                  }
                }}
                className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]"
              >
                <div className="flex items-center mb-4">
                  <img
                    src={testimonial.avatar}
                    className="w-14 h-14 rounded-full mr-4"
                    alt={testimonial.name}
                  />
                  <div>
                    <h3 className="text-white font-medium text-md 2xl:text-xl">
                      {testimonial.name}
                    </h3>
                    <p className="text-gray-400 text-md 2xl:text-xl">
                      {testimonial.role}
                    </p>
                  </div>
                </div>
                <p className="text-white 2xl:text-lg">
                  {testimonial.quote}
                </p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.div>
      <motion.div 
       initial="hidden"
       whileInView="visible"
       viewport={{ once: false, amount: 0.2 }}
       variants={sectionVariants}
       className="md:p-8 p-5 mt-14">
        <div className="flex justify-between flex-col lg:flex-row items-start lg:gap-0 gap-2">
          <div className="flex flex-col justify-start gap-2 lg:w-[50%] w-full">
            <h1 className="text-white font-serif font-medium text-xl sm:text-3xl xl:text-4xl 2xl:text-5xl">
              Contact Us
            </h1>
            <p className="text-[#d7dee4] text-sm sm:text-lg lg:text-xl 2xl:text-3xl">
              Get in touch with us for your next project.
            </p>
          </div>
          <motion.div 
           variants={scaleVariants}
           className="lg:w-[50%] w-full lg:p-2 mt-3 lg:mt-0">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="col-span-1">
                <input
                  type="text"
                  className="p-1 px-2 border border-[#3d3d3d] focus:outline-none w-full rounded bg-[#182026]"
                  placeholder="First Name"
                />
              </div>
              <div className="col-span-1">
                <input
                  type="text"
                  className="p-1 px-2 border border-[#3d3d3d] focus:outline-none w-full rounded bg-[#182026]"
                  placeholder="Last Name"
                />
              </div>
              <div className="col-span-2">
                <input
                  type="email"
                  className="p-1 px-2 border border-[#3d3d3d] focus:outline-none w-full rounded bg-[#182026]"
                  placeholder="Email"
                />
              </div>
              <div className="col-span-2">
                <input
                  type="textarea"
                  className="h-32 px-2 border border-[#3d3d3d] focus:outline-none w-full rounded bg-[#182026]"
                />
              </div>
            </div>
            <div className="flex justify-start">
            <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded mt-6"
              >
                Submit
              </motion.button>
            </div>
          </motion.div>
        </div>
      </motion.div>
      <div className="border border-b-[#252b2f] mb-10 mt-7"></div>
      <motion.div 
       initial="hidden"
       whileInView="visible"
       viewport={{ once: false, amount: 0.2 }}
       variants={sectionVariants}
       className="md:p-8 p-5">
        <div className="flex justify-center gap-7">
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
              <FaFacebook className="text-white bg-black" size={38} />
            </motion.div>
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
              <FaInstagram className="text-white bg-black" size={38} />
            </motion.div>
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
              <FaYelp className="text-white bg-black" size={38} />
            </motion.div>
          </div>
        <div className="text-center mt-10">
          <h1 className="text-white font-serif font-medium text-lg xl:text-xl 2xl:text-2xl">
            Building a Better Tomorrow
          </h1>
        </div>
        <motion.div 
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.2 }}
          variants={scaleVariants}
          className="flex justify-center gap-3 mt-3">
          <input
            type="text"
            className="p-1 px-2 rounded bg-[#182026]"
            placeholder="Email address"
          />
          <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded"
          >
            Subscribe
          </motion.button>
        </motion.div>
        <div className="text-center mt-5">
          <p className="text-sm md:text-lg text-[#d7dee4]">
            1234 Main St, Springfield, USA
            <br />
            info@buildpro.com
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default ContactUs;
