import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services";
import Projects from "./Pages/Projects";
import ContactUs from "./Pages/ContactUs";

function App() {
  return (
    <div className="bg-[#02080d]">
      <BrowserRouter>
       <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="*" element={<Navigate to="/home" replace />} />            

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
