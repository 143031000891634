import React from "react";
import { motion } from "framer-motion";
import image1 from "../Assets/engineer at site.jpeg";
import image2 from "../Assets/commercialcomplex.jpeg";
import image3 from "../Assets/apartments1.jpg";
import image4 from "../Assets/industrial park.jpeg";
import { FaFacebook, FaInstagram, FaYelp } from "react-icons/fa6";
const Services = () => {

  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: (delay) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 0.6,
        ease: "easeOut",
      },
    }),
  };

  const scaleVariants = {
    initial: { scale: 0.9, opacity: 0 },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.9,
        ease: "easeInOut",
      },
    },
  };

  const slideVariants = {
    initial: { x: -100, opacity: 0 },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.9,
        ease: "easeOut",
      },
    },
  };

  const fadeVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.9,
        ease: "easeOut",
      },
    },
  };
  const scrollVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        ease: "easeOut",
      },
    },
  };

  return (
    <div>
      <div className="md:p-8 p-5">
        <motion.div
          variants={textVariants}
          initial="hidden"
          animate="visible"
          custom={0.2}
          className="p-5 text-center"
        >
          <h1 className="text-white font-serif xl:text-7xl lg:text-6xl md:text-5xl text-3xl font-bold">
            Building the Future, One
            <br /> Project at a Time
          </h1>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          viewport={{ once: false }}
          className="flex justify-center mt-2 mb-5"
        >
          <img
            src={image1}
            alt="Construction Site"
            className="w-full 2xl:max-w-[2000px] max-w-[1100px] object-contain rounded-lg"
          />
        </motion.div>
      </div>
      <motion.div 
        initial="initial"
        whileInView="animate"
        viewport={{ once: false }}
        className="md:p-8 p-5 my-10">
        <div className="flex justify-between flex-col lg:flex-row items-start lg:gap-0 gap-2">
          <motion.div 
           variants={slideVariants} 
           className="flex justify-start lg:w-[50%]">
            <h1 className="text-white font-serif font-bold text-xl sm:text-3xl xl:text-4xl 2xl:text-5xl">
              Our Services
            </h1>
          </motion.div>
          <motion.div 
           variants={fadeVariants} 
           className="flex flex-row justify-between gap-10 lg:w-[50%] w-full lg:mt-0 mt-5">
            <motion.ul 
             initial={{ opacity: 0, x: -100 }}
             whileInView={{ opacity: 1, x: 0 }}
             transition={{ duration: 0.8, ease: "easeOut" }}
             viewport={{ once: false, amount: 0.3 }}
             className="text-[#d7dee4] text-sm sm:text-lg lg:text-xl 2xl:text-3xl w-[50%] space-y-2">
              <li>Commercial Construction</li>
              <li>Industrial Construction</li>
              <li>Project Management</li>
              <li>Pre-Construction</li>
              <li>International Projects</li>
            </motion.ul>
            <motion.ul 
             initial={{ opacity: 0, x: -100 }}
             whileInView={{ opacity: 1, x: 0 }}
             transition={{ duration: 0.8, ease: "easeOut" }}
             viewport={{ once: false, amount: 0.3 }}
             className="text-[#d7dee4] text-sm sm:text-lg lg:text-xl 2xl:text-3xl w-[50%] space-y-2">
              <li>Residential Construction</li>
              <li>Renovations</li>
              <li>Design-Build</li>
              <li>Sustainable Building</li>
            </motion.ul>
          </motion.div>
        </div>
      </motion.div>
      <motion.div 
       initial="initial"
       whileInView="animate"
       viewport={{ once: false }}
       className="md:p-8 p-5 my-10">
        <motion.h1 
         variants={slideVariants}
         className="text-white font-serif font-bold text-sm sm:text-xl xl:text-2xl 2x:text-3xl">
          Featured Projects
        </motion.h1>
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-4">
          <div className="rounded-lg overflow-hidden">
            <img
              src={image2}
              alt="commercial complex"
              className="w-full h-56 object-cover rounded-lg"
            />
            <div className="p-4 flex justify-center flex-col items-center gap-2">
              <h2 className="text-white font-bold font-serif text-lg 2xl:text-xl">
                Commercial Complex
              </h2>
              <p className="text-gray-400 text-sm 2xl:text-base">
                State-of-the-art commercial complex in New York
              </p>
              <button className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded mt-2">
                Learn More
              </button>
            </div>
          </div>
          <div className="rounded-lg overflow-hidden">
            <img
              src={image3}
              alt="residential apartments"
              className="w-full h-56 object-cover rounded-lg"
            />
            <div className="p-4 flex justify-center flex-col items-center gap-2">
              <h2 className="text-white font-bold font-serif text-lg 2xl:text-xl">
                Residential Apartments
              </h2>
              <p className="text-gray-400 text-sm 2xl:text-base">
                Luxury residential apartments in Paris{" "}
              </p>
              <button className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded mt-2">
                Learn More
              </button>
            </div>
          </div>
          <div className="rounded-lg overflow-hidden">
            <img
              src={image4}
              alt="industrial park"
              className="w-full h-56 object-cover rounded-lg"
            />
            <div className="p-4 flex justify-center flex-col items-center gap-2">
              <h2 className="text-white font-bold font-serif text-lg 2xl:text-xl">
                Industrial Park
              </h2>
              <p className="text-gray-400 text-sm 2xl:text-base">
                High-tech industrial park in Tokyo
              </p>
              <button className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded mt-2">
                Learn More
              </button>
            </div>
          </div>
        </div> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-4">
          {[
            { img: image2, title: "Commercial Complex", description: "State-of-the-art commercial complex in New York" },
            { img: image3, title: "Residential Apartments", description: "Luxury residential apartments in Paris" },
            { img: image4, title: "Industrial Park", description: "High-tech industrial park in Tokyo" }
          ].map((project, index) => (
            <motion.div 
              key={index}
              variants={scaleVariants}
              initial="initial"
              whileInView="animate"
              viewport={{ once: false }}
              className="rounded-lg overflow-hidden"
            >
              <img
                src={project.img}
                alt={project.title}
                className="w-full h-56 object-cover rounded-lg"
              />
              <div className="p-4 flex justify-center flex-col items-center gap-2">
                <h2 className="text-white font-bold font-serif text-lg 2xl:text-xl">
                  {project.title}
                </h2>
                <p className="text-gray-400 text-sm 2xl:text-base">
                  {project.description}
                </p>
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded mt-2">
                  Learn More
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
      <motion.div 
        initial="hidden" 
        whileInView="visible" 
        viewport={{ once: false, amount: 0.2 }}
        variants={scrollVariants}
        className="md:p-8 p-5">
        <div className="flex justify-center gap-7">
          {[FaFacebook, FaInstagram, FaYelp].map((Icon, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
            >
              <Icon className="text-white bg-black" size={38} />
            </motion.div>
          ))}
        </div>
        <motion.div 
         initial={{ opacity: 0, y: 90 }}
         whileInView={{ opacity: 1, y: 0 }}
         viewport={{ once: false }}
         transition={{ duration: 0.6 }}
          className="text-center mt-10">
          <h1 className="text-white font-serif font-medium text-lg xl:text-xl 2xl:text-2xl">
            Building a Better Tomorrow
          </h1>
        </motion.div>
        <motion.div 
         initial={{ opacity: 0, y: 90 }}
         whileInView={{ opacity: 1, y: 0 }}
         viewport={{ once: false }}
         transition={{ duration: 0.6 }}
         className="flex justify-center gap-3 mt-3">
          <input
            type="text"
            className="p-1 px-2 rounded bg-[#182026]"
            placeholder="Email address"
          />
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded">
            Subscribe
          </motion.button>
        </motion.div>
        <motion.div 
         initial={{ opacity: 0, y: 90 }}
         whileInView={{ opacity: 1, y: 0 }}
         viewport={{ once: false }}
         transition={{ duration: 0.6 }}
         className="text-center mt-5">
          <p className="text-sm md:text-lg text-[#d7dee4]">
            1234 Main St, Springfield, USA
            <br />
            info@buildpro.com
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Services;
