import React from "react";
import image1 from "../Assets/skyscraper.jpg";
import image2 from "../Assets/bridge.jpg";
import image3 from "../Assets/residency.webp";
import image4 from "../Assets/airport.webp";
import image5 from "../Assets/hospital.avif";
import image6 from "../Assets/mall.jpg";
import image7 from "../Assets/university.avif";
import image8 from "../Assets/factory.jpg";
import image9 from "../Assets/stadium.avif";
import image10 from "../Assets/constructionengineers.png";
import { motion } from "framer-motion";
import { FaFacebook, FaInstagram, FaYelp } from "react-icons/fa6";

const Projects = () => {
  
  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: (delay) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 0.6,
        ease: "easeOut",
      },
    }),
  };

  const scrollVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  const projectCardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.1,
        duration: 0.6,
        ease: "easeOut",
      },
    }),
  };
  return (
    <div>
      <div className="md:p-8 p-5">
        <motion.div
          variants={textVariants}
          initial="hidden"
          animate="visible"
          custom={0.1}
        >
          <p className="text-gray-400 text-md xl:text-lg 2xl:text-xl">
            Construction
          </p>
        </motion.div>
        <motion.div
          variants={textVariants}
          initial="hidden"
          animate="visible"
          custom={0.3}
          className="my-3"
        >
          <h1 className="text-2xl font-bold font-serif text-white text-xl sm:text-3xl xl:text-5xl 2xl:text-6xl">
            BuildCorp Projects
          </h1>
        </motion.div>
        <motion.div 
          variants={scrollVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-10"
        >
          {[
            { img: image1, title: "Skyscraper in New York", type: "Commercial" },
            { img: image2, title: "Bridge in San Francisco", type: "Infrastructure" },
            { img: image3, title: "Residential Complex in Tokyo", type: "Residential" },
            { img: image4, title: "Airport in Dubai", type: "Transportation" },
            { img: image5, title: "Hospital in London", type: "Healthcare" },
            { img: image6, title: "Mall in Paris", type: "Commercial" },
            { img: image7, title: "University in Sydney", type: "Educational" },
            { img: image8, title: "Factory in Berlin", type: "Industrial" },
            { img: image9, title: "Stadium in Rio", type: "Sports" }
          ].map((project, index) => (
            <motion.div 
              key={index} 
              variants={projectCardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              custom={index}
              className="rounded-lg overflow-hidden mb-3"
            >
              <motion.img
                src={project.img}
                alt={project.title}
                initial={{ scale: 0.9, opacity: 0 }}
                whileInView={{ 
                  scale: 1, 
                  opacity: 1,
                  transition: { 
                    duration: 0.6, 
                    ease: "easeOut" 
                  }
                }}
                viewport={{ once: false }}
                className="w-full h-56 object-cover rounded-lg"
              />
              <div className="py-4 flex flex-col items-start gap-1">
                <h2 className="text-white font-bold font-serif text-lg 2xl:text-xl">
                  {project.title}
                </h2>
                <p className="text-gray-400 text-sm 2xl:text-base">
                  {project.type}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
      <motion.div 
       initial={{ opacity: 0, y: 50 }}
       whileInView={{ 
         opacity: 1, 
         y: 0,
         transition: { 
           duration: 0.6, 
           ease: "easeOut" 
         }
       }}
       viewport={{ once: false }}
       className="md:p-8 p-5 mt-10">
        <div className="flex justify-between flex-col lg:flex-row items-start lg:gap-0 gap-2">
          <div className="flex justify-start lg:w-[50%]">
            <h1 className="text-white font-serif font-bold text-xl sm:text-3xl xl:text-4xl 2xl:text-5xl">
              Our Expertise
            </h1>
          </div>
          <div className="flex justify-end lg:w-[50%]">
            <p className="text-[#d7dee4] text-sm sm:text-lg lg:text-xl 2xl:text-3xl">
              BuildCorp has been delivering world-class construction projects
              across the globe. Our expertise spans various sectors including
              commercial, residential, infrastructure, healthcare and more.
            </p>
          </div>
        </div>
      </motion.div>
      <motion.div 
       initial={{ opacity: 0, scale: 0.9 }}
       whileInView={{ 
         opacity: 1, 
         scale: 1,
         transition: { 
           duration: 0.6, 
           ease: "easeOut" 
         }
       }}
       viewport={{ once: false }}
       className="flex flex-col items-center gap-2 mt-[100px] md:p-8 p-5">
        <h1 className="text-2xl font-bold font-serif text-white text-xl sm:text-3xl xl:text-5xl 2xl:text-6xl">
          Why Choose BuildCorp?
        </h1>
        <p className="text-[#d7dee4] text-sm sm:text-lg lg:text-xl 2xl:text-3xl 2xl:mt-1">
          Our team of experts ensures that every project is completed with the
          highest standards of quality and safety.
        </p>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded mt-6">
          Learn More
        </motion.button>
      </motion.div>
      <motion.div 
       initial={{ opacity: 0, scale: 0.95 }}
       whileInView={{ 
         opacity: 1, 
         scale: 1,
         transition: { 
           duration: 0.6, 
           ease: "easeOut" 
         }
       }}
       viewport={{ once: false }}
       className="md:p-8 p-5">
        <img
          src={image10}
          alt="Construction engineers"
          className="w-full object-cover rounded-lg"
        />
      </motion.div>
      <motion.div 
       initial="hidden" 
       whileInView="visible" 
       viewport={{ once: false, amount: 0.2 }}
       variants={scrollVariants}
       className="md:p-8 p-5 mt-10">
        <div className="flex justify-center gap-7">
          {[FaFacebook, FaInstagram, FaYelp].map((Icon, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
            >
              <Icon className="text-white bg-black" size={38} />
            </motion.div>
          ))}
        </div>
        <motion.div 
         initial={{ opacity: 0, y: 90 }}
         whileInView={{ opacity: 1, y: 0 }}
         viewport={{ once: false }}
         transition={{ duration: 0.6 }}
         className="text-center mt-10">
          <h1 className="text-white font-serif font-medium text-lg xl:text-xl 2xl:text-2xl">
            Building a Better Tomorrow
          </h1>
        </motion.div>
        <motion.div 
          initial={{ opacity: 0, y: 90 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.6 }}
          className="flex justify-center gap-3 mt-3">
          <input
            type="text"
            className="p-1 px-2 rounded bg-[#182026]"
            placeholder="Email address"
          />
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded">
            Subscribe
          </motion.button>
        </motion.div>
        <motion.div 
          initial={{ opacity: 0, y: 90 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.6 }}
          className="text-center mt-5">
          <p className="text-sm md:text-lg text-[#d7dee4]">
            1234 Main St, Springfield, USA
            <br />
            info@buildpro.com
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Projects;
