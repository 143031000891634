import { Menu, X } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const textVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: (delay) => ({ 
          opacity: 1, 
          y: 0,
          transition: {
            delay: delay,
            duration: 0.6,
            ease: "easeOut"
          }
        })
      };
  
    const NavLinks = () => (
      <>
        <Link to="/home" onClick={toggleMobileMenu}>
          <motion.p 
            variants={textVariants}
            initial="hidden"
            animate="visible"
            custom={0.2}
            className="text-[#D7DEE4] p-2 2xl:text-3xl xl:text-[18px] md:text-md sm:text-sm text-xs active:shadow-inner transform active:translate-y-0.5 cursor-pointer"
          >
            Home
          </motion.p>
        </Link>
        <Link to="/aboutus" onClick={toggleMobileMenu}>
          <motion.p 
            variants={textVariants}
            initial="hidden"
            animate="visible"
            custom={0.3}
            className="text-[#D7DEE4] p-2 2xl:text-3xl xl:text-[18px] md:text-md sm:text-sm text-xs active:shadow-inner transform active:translate-y-0.5 cursor-pointer"
          >
            About Us
          </motion.p>
        </Link>
        <Link to="/services" onClick={toggleMobileMenu}>
          <motion.p 
            variants={textVariants}
            initial="hidden"
            animate="visible"
            custom={0.4}
            className="text-[#D7DEE4] p-2 2xl:text-3xl xl:text-[18px] md:text-md sm:text-sm text-xs active:shadow-inner transform active:translate-y-0.5 cursor-pointer"
          >
            Services
          </motion.p>
        </Link>
        <Link to="/projects" onClick={toggleMobileMenu}>
          <motion.p 
            variants={textVariants}
            initial="hidden"
            animate="visible"
            custom={0.5}
            className="text-[#D7DEE4] p-2 2xl:text-3xl xl:text-[18px] md:text-md sm:text-sm text-xs active:shadow-inner transform active:translate-y-0.5 cursor-pointer"
          >
            Projects
          </motion.p>
        </Link>
        <Link to="/contactus" onClick={toggleMobileMenu}>
          <motion.p 
            variants={textVariants}
            initial="hidden"
            animate="visible"
            custom={0.6}
            className="text-[#D7DEE4] p-2 2xl:text-3xl xl:text-[18px] md:text-md sm:text-sm text-xs active:shadow-inner transform active:translate-y-0.5 cursor-pointer"
          >
            Contact
          </motion.p>
        </Link>
      </>
    );

  return (
     <div>
      <motion.div 
        variants={textVariants}
        initial="hidden"
        animate="visible"
        custom={0.1}
        className="flex justify-center"
      >
        <h1 className="p-3 text-white text-xl md:text-2xl xl:text-3xl 2xl:text-5xl tracking-[0.2em] font-sans">
          BUILDPRO
        </h1>
      </motion.div>

      <div className="hidden md:flex justify-center flex-wrap gap-8 2xl:gap-[50px]">
        <NavLinks />
      </div>

      <div className="md:hidden relative">
        <div 
          className="absolute right-4 bottom-0 z-20 cursor-pointer"
          onClick={toggleMobileMenu}
        >
          {isMobileMenuOpen ? (
            <X className="text-white" size={24} />
          ) : (
            <Menu className="text-black bg-white rounded" size={34} />
          )}
        </div>

        {isMobileMenuOpen && (
          <div 
            className="fixed inset-0 bg-black/70 z-10"
            onClick={toggleMobileMenu}
          />
        )}

        <div 
          className={`
            fixed top-0 right-0 w-64 h-full bg-[#1E293B] 
            transform transition-transform duration-300 ease-in-out 
            ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}
            z-30 pt-16 flex flex-col items-center space-y-4
          `}
        >
          <NavLinks />
        </div>
      </div>

      <div className="border border-b-[#FFFFFF80] mt-4"></div>
    </div>
  );
};


export default Navbar;
