import React from "react";
import image1 from "../Assets/cnss.jpg";
import image3 from "../Assets/StockCake-Construction.jpg";
import image4 from "../Assets/homes construction.jpg";
import image5 from "../Assets/office construuction.webp";
import image6 from "../Assets/factory construction.png";
import avatar from "../Assets/avatar.png";
import avatar5 from "../Assets/avatar5.png";
import avatar1 from "../Assets/avatar1.png";
import avatar2 from "../Assets/avatar2.png";
import avatar3 from "../Assets/avatar3.png";
import avatar4 from "../Assets/avatar4.png";
import { motion } from "framer-motion";
import { FaFacebook, FaInstagram, FaYelp } from "react-icons/fa6";
const scrollVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.7,
      ease: "easeOut",
    },
  },
};

const scaleVariants = {
  hidden: { scale: 0.9, opacity: 0 },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: "easeInOut",
    },
  },
};
const Home = () => {
  

  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: (delay) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 0.6,
        ease: "easeOut",
      },
    }),
  };

  return (
    <div className="">
      <motion.div 
        initial="hidden" 
        whileInView="visible" 
        viewport={{ once: false, amount: 0.2 }}
        variants={scrollVariants}
        className="md:p-8 p-5">
        <motion.div
          variants={textVariants}
          initial="hidden"
          animate="visible"
          custom={0.1}
          className="p-5 text-center"
        >
          <h1 className="text-white font-serif xl:text-7xl lg:text-6xl md:text-5xl text-3xl font-bold">
            Building the Future, <br /> Restoring the Past
          </h1>
        </motion.div>
        <motion.div 
         initial="hidden"
         whileInView="visible"
         viewport={{ once: false, amount: 0.2 }}
         variants={scaleVariants}
         className="flex justify-center mt-2">
          <img
            src={image1}
            alt="Construction Site"
            className="w-full 2xl:max-w-[2000px] max-w-[1100px] object-contain rounded-lg"
          />
        </motion.div>
      </motion.div>
      <motion.div 
        initial="hidden" 
        whileInView="visible" 
        viewport={{ once: false, amount: 0.2 }}
        variants={scrollVariants}
        className="relative">
        <img
          src={image3}
          alt="Construction Workers"
          className="w-full object-cover opacity-50"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
          <motion.h1 
           initial={{ opacity: 0, y: 40 }}
           whileInView={{ opacity: 1, y: 0 }}
           viewport={{ once: false }}
           transition={{ duration: 0.6 }}
           className="text-xl sm:text-3xl md:text-4xl 2xl:text-6xl font-serif font-bold mb-4">
            Global Presence, Local Expertise
          </motion.h1>
          <motion.p 
           initial={{ opacity: 0, y: 40 }}
           whileInView={{ opacity: 1, y: 0 }}
           viewport={{ once: false }}
           transition={{ duration: 0.6, delay: 0.2 }}
           className="text-sm sm:text-xl md:text-2xl 2xl:text-3xl">
            Mon-Fri: 9am - 6pm
            <br />
            1234 Main St, Springfield, USA
          </motion.p>
        </div>
      </motion.div>
      <motion.div 
       initial="hidden" 
       whileInView="visible" 
       viewport={{ once: false, amount: 0.2 }}
       variants={scrollVariants}
       className="md:p-8 p-5">
        <h1 className="text-white font-serif font-bold text-sm sm:text-xl xl:text-2xl 2x:text-3xl">
          Our Services
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 mt-4">
          {[
            { img: image4, title: "Homes", subtitle: "Residential Construction" },
            { img: image5, title: "Offices", subtitle: "Commercial Construction" },
            { img: image6, title: "Factories", subtitle: "Industrial Construction" }
          ].map((service, index) => (
            <motion.div 
              key={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.2 }}
              variants={scaleVariants}
              transition={{ delay: index * 0.2 }}
              className="rounded-lg overflow-hidden"
            >
              <img
                src={service.img}
                alt={service.title}
                className="w-full h-56 object-cover rounded-lg"
              />
              <div className="p-4 flex justify-center flex-col items-center gap-2">
                <h2 className="text-white font-bold font-serif text-lg 2xl:text-xl">
                  {service.title}
                </h2>
                <p className="text-gray-400 text-sm 2xl:text-base">
                  {service.subtitle}
                </p>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded mt-2"
                >
                  Learn More
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
      <div className="border border-b-[#252b2f] mb-5"></div>
      <div className="md:p-8 p-5 mt-[100px]">
        <div className="flex justify-between flex-col lg:flex-row items-start lg:gap-0 gap-2">
          <div className="flex justify-start lg:w-[50%]">
            <motion.h1 
             initial={{ opacity: 0, y: 90 }}
             whileInView={{ opacity: 1, y: 0 }}
             viewport={{ once: false }}
             transition={{ duration: 0.6 }}
             className="text-white font-serif font-bold text-xl sm:text-3xl xl:text-4xl 2xl:text-5xl">
              Why Choose Us?
            </motion.h1>
          </div>
          <div className="flex justify-end lg:w-[50%]">
            <motion.p 
             initial={{ opacity: 0, y: 90 }}
             whileInView={{ opacity: 1, y: 0 }}
             viewport={{ once: false }}
             transition={{ duration: 0.6 }}
             className="text-[#d7dee4] text-sm sm:text-lg lg:text-xl 2xl:text-3xl">
              With over 50 years of experience, we provide top notch
              construction services worldwide. Our team of experts ensures every
              project is completed on time and within budget.
            </motion.p>
          </div>
        </div>
      </div>
      <div 
       className="p-8 mt-[80px] ">
        <motion.div 
         initial={{ opacity: 0, x: -100 }}
         whileInView={{ opacity: 1, x: 0 }}
         transition={{ duration: 0.8, ease: "easeOut" }}
         viewport={{ once: false, amount: 0.3 }}
         className="md:p-5 md:px-[9%] text-center">
          <h1 className="text-white font-serif font-medium text-2xl sm:text-4xl xl:text-5xl 2xl:text-6xl">
            BuildPro transformed our vision into reality. Their professionalism
            and attention to detail were outstanding.
          </h1>
          <div className="flex justify-center items-center mt-8">
            <img
              src={avatar1}
              alt="Alexandra Reade"
              className="rounded-full w-14 2xl:w-16 2xl:h-16 h-14 2xl:w-16 2xl:h-16 mr-4 text-white"
            />
            <div className="text-left">
              <p className="text-[#d7dee4] font-medium text-md 2xl:text-xl">
                Alexandra Reade
              </p>
              <p className="text-[#828d97] text-gray-400 text-md 2xl:text-xl">
                CEO, TechCorp
              </p>
            </div>
          </div>
        </motion.div>
      </div>
      <motion.div 
        initial={{ opacity: 0, y: 90 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false }}
        transition={{ duration: 0.6 }}
        className="md:p-8 p-5 mt-[80px] mb-10">
        <h1 className="mb-10 text-white font-serif font-bold text-xl sm:text-2xl xl:text-4xl 2xl:text-5xl text-center">
          What Our Clients Say
        </h1>
        <div className="overflow-x-auto scrollbar-hidden pb-4">
          <div className="flex space-x-4 px-4">
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar2}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="John Doe"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    John Doe
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">
                    Project Manager
                  </p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                BuildPro exceeded our expectations with their professionalism.
              </p>
            </div>
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar3}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="Jane Smith"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    Jane Smith
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">
                    CEO, Global Enterprises
                  </p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                The team was efficient and delivered on time.
              </p>
            </div>
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar4}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="Michael Brown"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    Carlos Ruiz
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">Architect</p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                Their attention to detail is unmatched.
              </p>
            </div>
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar5}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="Emily Chen"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    Emily Zhang
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">
                    Operations Drirector
                  </p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                A reliable partner for our customer needs.
              </p>
            </div>
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="Emily Chen"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    Emily Chen
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">Architect</p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                Seamless collaboration and exceptional craftsmanship.
              </p>
            </div>
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="Emily Chen"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    Emily Chen
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">Architect</p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                Seamless collaboration and exceptional craftsmanship.
              </p>
            </div>
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="Emily Chen"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    Emily Chen
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">Architect</p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                Seamless collaboration and exceptional craftsmanship.
              </p>
            </div>
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="Emily Chen"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    Emily Chen
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">Architect</p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                Seamless collaboration and exceptional craftsmanship.
              </p>
            </div>
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="Emily Chen"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    Emily Chen
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">Architect</p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                Seamless collaboration and exceptional craftsmanship.
              </p>
            </div>
            <div className="flex-shrink-0 bg-[#182026] p-5 rounded-lg w-[280px] 2xl:w-[320px]">
              <div className="flex items-center mb-4">
                <img
                  src={avatar}
                  className="w-14 h-14 rounded-full mr-4"
                  alt="Emily Chen"
                />
                <div>
                  <h3 className="text-white font-medium text-md 2xl:text-xl">
                    Emily Chen
                  </h3>
                  <p className="text-gray-400 text-md 2xl:text-xl">Architect</p>
                </div>
              </div>
              <p className="text-white 2xl:text-lg">
                Seamless collaboration and exceptional craftsmanship.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div 
       initial="hidden" 
       whileInView="visible" 
       viewport={{ once: false, amount: 0.2 }}
       variants={scrollVariants}
       className="md:p-8 p-5">
        <div className="flex justify-center gap-7">
          {[FaFacebook, FaInstagram, FaYelp].map((Icon, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
            >
              <Icon className="text-white bg-black" size={38} />
            </motion.div>
          ))}
        </div>
        <motion.div 
         initial={{ opacity: 0, y: 90 }}
         whileInView={{ opacity: 1, y: 0 }}
         viewport={{ once: false }}
         transition={{ duration: 0.6 }}
         className="text-center mt-10">
          <h1 className="text-white font-serif font-medium text-lg xl:text-xl 2xl:text-2xl">
            Building a Better Tomorrow
          </h1>
        </motion.div>
        <motion.div 
         initial={{ opacity: 0, y: 90 }}
         whileInView={{ opacity: 1, y: 0 }}
         viewport={{ once: false }}
         transition={{ duration: 0.6 }}
         className="flex justify-center gap-3 mt-3">
          <input
            type="text"
            className="p-1 px-2 rounded bg-[#182026]"
            placeholder="Email address"
          />
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded">
            Subscribe
          </motion.button>
        </motion.div>
        <motion.div 
         initial={{ opacity: 0, y: 90 }}
         whileInView={{ opacity: 1, y: 0 }}
         viewport={{ once: false }}
         transition={{ duration: 0.6 }}
         className="text-center mt-5">
          <p className="text-sm md:text-lg text-[#d7dee4]">
            1234 Main St, Springfield, USA
            <br />
            info@buildpro.com
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Home;
